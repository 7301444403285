.canvas-visualizer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  #audio-visualizer-canvas {
    transform: rotate(-90deg);
  }
}